<template>
  <b-container fluid>
    <b-card>
      <metabase
        :code="3124"
        :question="true"
      />
    </b-card>
  </b-container>
</template>
<script>
import {
    BContainer,
    BCard,
} from 'bootstrap-vue';

import Metabase from '../components/Metabase.vue';

export default {
    components: {
        BContainer,
        BCard,
        Metabase,
    },
};
</script>
